html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, blockquote, .ui.header, .ui.button, .ui.form input[type=text], .ui.form input[type=number], .ui.form input[type=tel], .ui.form input[type=password], select, .ui.modal > .header, .ui.message .header
  font-family: -apple-system, BlinkMacSystemFont, Poppins, Arial, Helvetica, "文泉驛正黑", "WenQuanYi Zen Hei", "儷黑 Pro", "LiHei Pro", 微軟正黑體, "Microsoft JhengHei", sans-serif

i.fa.icon, i.fas.icon
  font-family: "Font Awesome 5 Pro"
  font-weight: 900

.ui.borderless.main.menu .header.item, .noselect
  cursor: default
  -webkit-touch-callout: none
  /* iOS Safari
  -webkit-user-select: none
  /* Safari
  -khtml-user-select: none
  /* Konqueror HTML
  -moz-user-select: none
  /* Firefox
  -ms-user-select: none
  /* Internet Explorer/Edge
  user-select: none
  /* Non-prefixed version, currently
   * supported by Chrome and Opera

.ui.borderless.main.menu
  background-color: #000
  > .ui.container >
    .item
      color: #fff
    .active.item
      background-color: #777
    .item:hover
      color: #ababab
    .active.item:hover
      color: #000
    .header.item:hover
      color: #fff
    .right.menu > .item
      color: #fff

// body > .ui.pushable.basic.segment
//   overscroll-behavior-y: none
//   margin-top: 48px
//   margin-bottom: 0

//   > pusher
//     margin-bottom: 40px

html, body
  height: 100%
  font-size: 20px !important

table
  width: 100%

h1.ui.header.inline-header
  display: inline-block
  margin-right: 8px

// .field label .required
//   color: red

.ui.red.nag
  background: #e74c3c
  margin-bottom: 12px
  &:hover
    background: #c0392b

.main-body
  height: auto
  min-height: 100%
  font-size: 24px
  > .menu
    >
      .main-menu-back
        position: fixed
        top: 0
        bottom: -120px
        z-index: 1
        width: 160px
        background-color: #23282d
      .main-menu-wrap
        position: relative
        float: left
        z-index: 9990
        padding: 4px 12px
        font-size: 24px
        width: 160px
        color: #fff
        position: fixed
        float: left

.menu
  h1
    font-size: 56px
    margin-bottom: 0
    text-align: center
    cursor: default
  h2
    font-size: 32px
    margin-top: 0
    margin-bottom: 48px
    text-align: center
    cursor: default
  .header .dyc
    font-family: "The Bold Font", Poppins, Arial, Helvetica, 文泉驛正黑, "WenQuanYi Zen Hei", "儷黑 Pro", "LiHei Pro", 微軟正黑體, "Microsoft JhengHei", sans-serif

.main-menu-items
  clear: left
  margin: 12px 0
  padding: 0
  list-style: none

.main-menu-item
  border: none
  min-height: 46px
  position: relative

.menu a
  color: #fff
  &:focus, &:hover
    color: #00b9eb
    text-decoration: none

.main-body > .main-content
  background-color: #fff
  margin-left: 160px
  height: 100%
  padding: 16px 40px

.ui.item.dropdown .menu
  z-index: 1000

.field label .required
  display: inline-block
  color: red
  font-size: 0.75em

.ui.indicating.progress
  &[data-warn^="success"] .bar
    background-color: #21ba45 !important
  &[data-warn^="warning"] .bar
    background-color: #f2c037 !important

.ct-barlabel
  font-size: 16px

.ct-series-b text
  color: #fff
  &::after
    content: 'g'

.ui.table td.pack-container
  text-align: center

td.pack-container input.pack,
td.value-container input
  transform: scale(2)

.bean.page .ui.header
  &.title
    display: inline
    margin-top: 16px
    margin-bottom: 8px
    // .buttons
    //   display: inline
  &.subtitle
    color: #777
    margin-top: 4px

td .inline-block
    display: inline

.ui.card.user .vip .icon
  color: #f39c12

.time.expired
  color: #fff

.time.expiring
  color: #c0392b

.time.warning
  color: #f39c12

.time.ok
  color: #27ae60

.batch.card
  text-align: center

// .batch.card .content .detail
//   position: absolute
//   bottom: 4px

.right.floated
  float: right

.bean.page .ui.header .price,
.batch.page .ui.header .price
  color: #444

// .bean.page .ui.header.roast-detail
.bean.page .ui.header.detail,
.batch.page .ui.header.detail
  margin-top: 4px

.bean.page .icon-detail img,
.batch.page .icon-detail img
  width: 100px

.ui.feed#notes .event.note
  cursor: pointer

.ui.feed#notes .event
  user-select: none
  -webkit-user-select: none

.register
  cursor: pointer

$background-color: hsl(50, 5%, 97%)
$black: hsl(200, 40%, 10%)
$white: $background-color
$base-font-size: 2em
$base-line-height: 1.2em

blockquote
  // width: 100%
  margin-bottom: 3em

.brooks
  position: relative
  // font-family: 'Sanchez', serif
  font-size: $base-font-size
  line-height: $base-line-height
  font-weight: bold
  font-style: italic

  &:before
    content: '\201C'
    position: absolute
    top: 0.25em
    left: -0.15em
    color: darken($white, 7%)
    font-size: 6em
    z-index: -1

  footer
    display: none

.ludwig
  position: relative
  padding-left: 1em
  border-left: 0.2em solid lighten($black, 40%)
  // font-family: 'Roboto', serif
  font-size: $base-font-size
  line-height: $base-line-height
  font-weight: 100

  &:before, &:after
    content: '\201C'
    font-family: 'Sanchez'
    color: lighten($black, 40%)

  &:after
    content: '\201D'

  footer
    display: none

.groucho
  position: relative
  // font-family: 'Sanchez', serif
  font-size: $base-font-size
  line-height: $base-line-height

  footer
    // font-family: 'Noto Sans', sans-serif
    font-size: 0.6em
    font-weight: 700
    // color: darken($white, 15%)
    color: lighten($black, 40%)
    float: right

    &:before
      content: '\2015'

  &:after
    content: '\201D'
    position: absolute
    top: 0.28em
    right: 0
    font-size: 6em
    font-style: italic
    color: darken($white, 7%)
    z-index: -1

.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut
  stroke: #16a085

.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut
  stroke: #2980b9

=transform($property)
  -webkit-transform: $property
  -webkit-transform: $property
  -o-transform: $property
  -ms-transform: $property
  transform: $property

// #preview
//   +transform(rotate(90deg))

table#preorder, table#booking
  tr.delivered, tr.cancelled, tr.arrived
    color: #888
    background-color: #CCC

.predict
  color: #999

.ui.compact.stackable.grid > .row > .column:first-child
  padding-left: 0 !important

.ui.active.button,
.ui.active.button:hover
  background-color: #21ba45
  color: #fff
